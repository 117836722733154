import FinalProgressPlayer from "./FinalProgressPlayer";

function FinalProgress({
	finalPlayers,
	finalPlayersOrder,
	countFinalPlayers,
	playersPictures,
}) {
	return (
		<div className="main-flex">
			<div
				id="final-progress"
				className={"final-box-" + countFinalPlayers}
			>
				{finalPlayers.map((player, index) => (
					<FinalProgressPlayer
						key={player.id}
						player={player}
						index={index}
						order={finalPlayersOrder[player.id].order}
						countFinalPlayers={countFinalPlayers}
						picture={playersPictures[player.id]}
					/>
				))}
			</div>
			<div className="picture-cup"></div>
		</div>
	);
}

export default FinalProgress;
