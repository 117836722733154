function FinalPlayerPicture({ picture }) {
	return (
		<div className="box-player-picture">
			<div
				className="player-picture"
				style={{ backgroundImage: `url("${picture}")` }}
			>
			</div>
		</div>
	);
}

export default FinalPlayerPicture;
