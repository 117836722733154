function StatsFull({ fullStats, players, playersPictures }) {
	console.log(fullStats);

	const dataPlayers = players.reduce((acc, onePlayer) => {
		acc[onePlayer.id] = onePlayer;
		return acc;
	}, {});

	return (
		<div className="stats-content-box-lg">
			<table className="ReactTable">
				<thead>
					<tr>
						<th>VS</th>
						{Object.entries(fullStats).map(([key, arr]) => {
							return (
								<th className="defense">
									{dataPlayers[key].firstName}
								</th>
							);
						})}
					</tr>
					{Object.entries(fullStats).map(([key, arr]) => {
						return (
							<tr>
								<td className="attack">
									{dataPlayers[key].firstName}
								</td>
								{Object.entries(arr).map(([key, value]) => {
									return <td className="value">{value}</td>;
								})}
							</tr>
						);
					})}
				</thead>
			</table>
		</div>
	);
}

export default StatsFull;
