function StatsTitle({ stepStats }) {
	let title;
	if (stepStats === "ranking") {
		title = "Classement final !";
	} else if (stepStats === "bestWinners") {
		title = "Le clown de service !";
	} else if (stepStats === "badWinners") {
		title = "Le p'tit réservé de la bande !";
	} else if (stepStats === "bestLosers") {
		title = "Le bon public !";
	} else if (stepStats === "badLosers") {
		title = "Le maître de la zenitude !";
	} else if (stepStats === "fullStats") {
		title = "Stats complètes !";
	}

	return <div className="stats-title">{title}</div>;
}

export default StatsTitle;
