import axios from "axios";

const LoadGame = async (gameAppCode) => {
	try {
		const resp = await axios.get(
			process.env.REACT_APP_MAIN_API +
				"/api/game-play/common/game/" +
				gameAppCode
		);
		return resp.data;
	} catch (err) {
		throw new Error("Impossible de charger la partie");
	}
};

export default LoadGame;
