import { useRef } from "react";
import { Fireworks } from "@fireworks-js/react";
import { FireworksHandlers } from "@fireworks-js/react";

function FireworksApp() {
	const ref = useRef(null);
	return (
		<Fireworks
			ref={ref}
			options={{
				autoresize: true,
				opacity: 0.3,
				acceleration: 1.05,
				friction: 0.95,
				gravity: 1.2,
				particles: 55,
				traceLength: 3,
				traceSpeed: 10,
				explosion: 6,
				intensity: 30,
				flickering: 0,
				lineStyle: "round",
				hue: {
					min: 0,
					max: 360,
				},
				delay: {
					min: 15,
					max: 30,
				},
				rocketsPoint: {
					min: 25,
					max: 75,
				},
				lineWidth: {
					explosion: {
						min: 1,
						max: 3,
					},
					trace: {
						min: 0.1,
						max: 1,
					},
				},
				brightness: {
					min: 50,
					max: 80,
				},
				decay: {
					min: 0.015,
					max: 0.03,
				},
				mouse: {
					click: false,
					move: false,
					max: 1,
				},
			}}
			style={{
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				position: "fixed",
			}}
		/>
	);
}

export default FireworksApp;
