import cupImg from "../../assets/img/coupe2.png";

function Winner({ winners, playersPictures }) {
	return (
		<div
			className={"main-final-result main-final-result-" + winners.length}
		>
			<div>
				<img className="main-final-picture-cup" src={cupImg} />
				<div className="main-final-result-title">
					{winners.length === 1
						? "Et le gagnant de cet Hilarious Game est"
						: "Et les gagnants de cet Hilarious Game sont"}
				</div>
				{winners.map((winner) => (
					<div className="main-final-result-name">
						{winner.firstName}
					</div>
				))}
				{winners[0] && (
					<div
						className="main-final-picture-user main-final-picture-user-0"
						style={{
							backgroundImage: `url("${
								playersPictures[winners[0].id]
							}")`,
						}}
					/>
				)}
				{winners[1] && (
					<div
						className="main-final-picture-user main-final-picture-user-1"
						style={{
							backgroundImage: `url("${
								playersPictures[winners[1].id]
							}")`,
						}}
					/>
				)}
				{winners[2] && (
					<div
						className="main-final-picture-user main-final-picture-user-2"
						style={{
							backgroundImage: `url("${
								playersPictures[winners[2].id]
							}")`,
						}}
					/>
				)}
				{winners[3] && (
					<div
						className="main-final-picture-user main-final-picture-user-3"
						style={{
							backgroundImage: `url("${
								playersPictures[winners[3].id]
							}")`,
						}}
					/>
				)}
			</div>
		</div>
	);
}

export default Winner;
