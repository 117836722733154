import Countdown from "react-countdown";

function TimerFinal({ startDate, duration }) {
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed || startDate === null) {
			return (
				<div id="timer">
					<div>
						<span className="minutes">00</span>
					</div>
					<div className="separator">:</div>
					<div>
						<span className="seconds">00</span>
					</div>
				</div>
			);
		} else {
			return (
				<div id="timer">
					<div>
						<span className="minutes">
							{String(minutes).padStart(2, "0")}
						</span>
					</div>
					<div className="separator">:</div>
					<div>
						<span className="seconds">
							{String(seconds).padStart(2, "0")}
						</span>
					</div>
				</div>
			);
		}
	};

	return (
		<>
			{startDate === null ? (
				<div id="timer">
					<div>
						<span className="minutes">00</span>
					</div>
					<div className="separator">:</div>
					<div>
						<span className="seconds">00</span>
					</div>
				</div>
			) : (
				<Countdown
					date={startDate + duration * 60 * 1000}
					renderer={renderer}
					autoStart={true}
				/>
			)}
		</>
	);
}

export default TimerFinal;
