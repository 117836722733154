import "./Main.css";
import { useEffect, useState, useRef } from "react";
import Header from "./Component/Header/Header";
import Loading from "./Component/Loading/Loading";
import LoadGame from "./Api/LoadGame";
import Timer from "./Component/Timer/Timer";
import TimerFinal from "./Component/Timer/TimerFinal";
import RankingList from "./Component/RankingList/RankingList";
import soundStart from "./assets/sound/start.mp3";
import BoxScenario from "./Component/BoxScenario/BoxScenario";
import StatsTitle from "./Component/StatsTitle/StatsTitle";
import StatsContent from "./Component/StatsContent/StatsContent";
import ReactAudioPlayer from "react-audio-player";
import WaitingFinalChoice from "./Component/Final/WaitingFinalChoice";
import ModalFinalChoice from "./Component/Final/ModalFinalChoice";
import FireworksApp from "./Component/Fireworks/Fireworks";
import FinalProgress from "./Component/Final/FinalProgress";
import Winner from "./Component/Final/Winner";
import InputAppCode from "./Component/InputAppCode/InputAppCode";
import Finish from "./Component/Finish/Finish";

function App() {
	const [isLoading, setIsLoading] = useState(true);
	const [game, setGame] = useState({});
	const [gameState, setGameState] = useState(null);
	const [config, setConfig] = useState(null);
	const [players, setPlayers] = useState([]);
	const [playersPictures, setPlayersPictures] = useState({});
	const [startDate, setStartDate] = useState(null);
	const [startFinalDate, setStartFinalDate] = useState(null);
	const [gameScenario, setGameScenario] = useState(null);
	const [stats, setStats] = useState(null);
	const [stepStats, setStepStats] = useState(null);
	const [dataScoring, setDataScoring] = useState([]);
	const [cssMainClass, setCssMainClass] = useState("");
	const [finalPlayers, setFinalPlayers] = useState([]);
	const [finalPlayersOrder, setFinalPlayersOrder] = useState([]);
	const [finalWinners, setFinalWinners] = useState([]);
	const [displayFirework, setDisplayFirework] = useState(false);
	const [screen, setScreen] = useState(null);

	const searchParams = new URLSearchParams(document.location.search);
	const appCode = searchParams.get("g");
	const cleanAll = searchParams.get("cleanAll");

	if (cleanAll !== null) {
		localStorage.removeItem("play-sound-start-" + appCode);
	}
	const playSoundStart = localStorage.getItem("play-sound-start-" + appCode);

	/* ********************************************************************************** */
	/* LOAD GAME */
	/* ********************************************************************************** */
	useEffect(() => {
		if (appCode !== null) {
			loadGame();
		} else {
			// TODO : Gérer le cas où il n'y a pas d'appCode
		}
	}, []);

	const loadGame = async () => {
		const data = await LoadGame(appCode);
		setGame(data.game);
		setConfig(data.config);
		if (
			data.game.state === "IN_FINAL_PROGRESS" &&
			data.game.hasOwnProperty("finalStartDate")
		) {
			const date = new Date(data.game.finalStartDate);
			setStartFinalDate(date.getTime());
		} else if (data.game.hasOwnProperty("startDate")) {
			const date = new Date(data.game.startDate);
			setStartDate(date.getTime());
		}
		if (data.hasOwnProperty("scenario")) {
			setGameScenario(data.scenario);
		}
		setGameState(data.game.state);
		resolvePlayers(data.players);
		resolveCssMainClass(data.players);
		resolveStateUi(data.game.state, false, data.game);
		if (data.hasOwnProperty("finalPlayers")) {
			resolveFinalPlayers(data.finalPlayers);
		}
		if (data.hasOwnProperty("finalWinners")) {
			setFinalWinners(data.finalWinners);
		}
	};

	const handleClickButtonSetAppCode = (appCodeText) => {
		window.location.href = "?g=GAME-" + appCodeText;
	};

	/* ********************************************************************************** */
	/* RESOLVER */
	/* ********************************************************************************** */
	useEffect(() => {
		const timoutDataScoring = setTimeout(() => {
			setDataScoring([]);
		}, 3000);
		return () => clearTimeout(timoutDataScoring);
	}, [dataScoring]);

	/* ********************************************************************************** */
	/* RESOLVER */
	/* ********************************************************************************** */
	const resolvePlayers = (dataPlayers) => {
		const dataPlayersPictures = dataPlayers.reduce((acc, onePlayer) => {
			acc[onePlayer.id] = onePlayer.picture;
			return acc;
		}, {});
		const formatDataPlayers = dataPlayers.map(({ picture, ...rest }) => {
			return rest;
		});
		setPlayers(formatDataPlayers);
		setPlayersPictures(dataPlayersPictures);
	};

	const resolveCssMainClass = (dataPlayers) => {
		let countPlayers = dataPlayers.length;
		let mainClass = "";
		if (countPlayers < 10) {
			mainClass = "main-default";
		} else if (countPlayers < 17) {
			mainClass = "main-" + String(countPlayers);
		}
		setCssMainClass(mainClass);
	};

	const resolveFinalPlayers = (dataFinalPlayers) => {
		const dataFinalPlayersOrder = dataFinalPlayers.reduce(
			(acc, onePlayer) => {
				acc[onePlayer.id] = { ranking: onePlayer.finalRanking };
				return acc;
			},
			{}
		);
		let rankings = Object.keys(dataFinalPlayersOrder).reduce((acc, key) => {
			acc.push(dataFinalPlayersOrder[key].ranking);
			return acc;
		}, []);
		rankings = rankings.sort().reverse();
		let order = [];
		let position = 1;
		let prevRanking = null;
		rankings.forEach((ranking) => {
			if (prevRanking !== null && prevRanking !== ranking) {
				position++;
			}
			order[ranking] = position;
			prevRanking = ranking;
		});
		for (const [key, value] of Object.entries(dataFinalPlayersOrder)) {
			dataFinalPlayersOrder[key].order = order[value.ranking];
		}
		setFinalPlayers(dataFinalPlayers);
		setFinalPlayersOrder(dataFinalPlayersOrder);
	};

	const resolveStateUi = (state, reloadGame, game) => {
		if (state === "WAITING_GAME") {
			if (reloadGame) {
				loadGame();
			}
			setScreen("ranking");
			setStartDate(null);
			setGameScenario(null);
			setIsLoading(false);
			setDisplayFirework(false);
		} else if (state === "IN_PROGRESS") {
			setScreen("ranking");
			setIsLoading(false);
			setDisplayFirework(false);
		} else if (state === "DEMO") {
			setScreen("ranking");
			setIsLoading(false);
			setDisplayFirework(false);
		} else if (state === "WAITING_FINAL_CHOICE") {
			setScreen("final_choice");
			setIsLoading(false);
			setDisplayFirework(true);
		} else if (state === "FINAL_CHOICE") {
			setScreen("final_choice");
			setIsLoading(false);
			setDisplayFirework(true);
		} else if (state === "FINAL_CHALLENGE_SELECTED") {
			setScreen("final");
			setIsLoading(false);
			setDisplayFirework(false);
		} else if (state === "IN_FINAL_PROGRESS") {
			setScreen("final");
			setIsLoading(false);
			setDisplayFirework(false);
		} else if (state === "POST_GAME") {
			setScreen("post_game");
			if (game.hasFinal) {
				setIsLoading(false);
				setDisplayFirework(true);
			} else {
				setIsLoading(true);
				setDisplayFirework(false);
			}
		} else if (state === "FINISHED") {
			setIsLoading(false);
			setDisplayFirework(false);
			setScreen("finish");
		} else {
			setDisplayFirework(false);
		}
	};

	/* ********************************************************************************** */
	/* MERCURE EVENT */
	/* ********************************************************************************** */
	useEffect(() => {
		if (config) {
			const url = new URL(config.MERCURE_URL);
			url.searchParams.append(
				"topic",
				process.env.REACT_APP_MERCURE_TOPIC_URI + appCode
			);
			const eventSource = new EventSource(url);
			eventSource.onmessage = (e) => {
				const data = JSON.parse(e.data);
				resolveMercureEvent(data);
			};
		}
	}, [config]);

	const resolveMercureEvent = (data) => {
		if (data.state) {
			setGameState(data.state);
		}
		if (data.event === "UPDATE_STATE") {
			resolveStateUi(data.state, true, game);
		} else if (data.event === "UPDATE_RANKING") {
			setPlayers(data.ranking);
			setDataScoring(data.datas);
		} else if (data.event === "START_GAME") {
			const date = new Date(data.startDate);
			setStartDate(date.getTime());
		} else if (
			data.event === "END_GAME" ||
			data.event === "FINAL_PLAYERS_ELIMINATE" ||
			data.event === "FINAL_PLAYER_REINTEGRATE"
		) {
			resolveStateUi(data.state, false, game);
			if (data.finalPlayers) {
				resolveFinalPlayers(data.finalPlayers);
			}
			if (data.finalWinners) {
				setFinalWinners(data.finalWinners);
			}
		} else if (data.event === "FINAL_CHALLENGE_SELECTED") {
			resolveStateUi(data.state, false, game);
		} else if (data.event === "START_FINAL_GAME") {
			const date = new Date(data.startFinalDate);
			setStartFinalDate(date.getTime());
			resolveStateUi(data.state, false, game);
		} else if (data.event === "START_DEMO") {
			const date = new Date(data.startDate);
			setStartDate(date.getTime());
		} else if (data.event === "START_EVENT") {
			setGameScenario(data.scenario);
		} else if (data.event === "END_EVENT") {
			setGameScenario(null);
		} else if (data.event === "RUN_STATS") {
			setStats(data.stats);
			setStepStats("ranking");
			setGameScenario(null);
			setIsLoading(false);
			setDisplayFirework(false);
		} else if (data.event === "NEXT_STATS") {
			setStepStats(data.step);
			setGameScenario(null);
		}
	};

	let playSoundStartBox = <></>;
	if (playSoundStart === null) {
		playSoundStartBox = <ReactAudioPlayer src={soundStart} autoPlay />;
		localStorage.setItem("play-sound-start-" + appCode, true);
	}

	if (appCode === null) {
		return (
			<main>
				<InputAppCode
					handleClickButtonSetAppCode={handleClickButtonSetAppCode}
				/>
			</main>
		);
	}

	/* ********************************************************************************** */
	/* RENDER */
	/* ********************************************************************************** */
	return (
		<main
			className={
				cssMainClass +
				(gameScenario !== null ||
				(game.hasFinal &&
					(gameState === "WAITING_FINAL_CHOICE" ||
						gameState === "FINAL_CHOICE"))
					? " main-side"
					: "") + 
					(game.type === "XXL" 
						? " game-xxl" 
						: ""
					)
			}
		>
			{isLoading ? (
				<Loading state={gameState} />
			) : (
				<>
					{playSoundStartBox}
					<div className="logo-back"></div>
					<div className="logo"></div>
					{gameState === "FINAL_CHOICE" && (
						<ModalFinalChoice player={finalPlayers[0]} />
					)}
					<Header game={game} />
					{["ranking", "final_choice"].includes(screen) && (game.type === "NORMAL" || (game.type === "XXL" && gameScenario !== null)) && (
						<Timer startDate={startDate} duration={game.duration} />
					)}
					{screen === "final" && (
						<TimerFinal
							startDate={startFinalDate}
							duration={game.finalDuration}
						/>
					)}
					{stepStats !== null && screen !== "finish" && <StatsTitle stepStats={stepStats} />}

					<div
						className={
							"main-content " +
							(game.hasFinal &&
							screen === "post_game" &&
							stepStats === null
								? " main-column"
								: "") + 
							(game.type === "XXL" 
								? " main-xxl" 
								: ""
							)
						}
					>
						{["ranking", "final_choice"].includes(screen) && game.type === "XXL" && gameScenario === null && (
							<Timer startDate={startDate} duration={game.duration} />
						)}
						{stepStats !== null && screen !== "finish" && (
							<StatsContent
								stepStats={stepStats}
								stats={stats}
								players={players}
								playersPictures={playersPictures}
								dataScoring={dataScoring}
							/>
						)}
						{["ranking", "final_choice"].includes(screen) && game.type === "NORMAL" && (
							<RankingList
								players={players}
								playersPictures={playersPictures}
								dataScoring={dataScoring}
							/>
						)}
						{gameScenario !== null && (
							<BoxScenario
								gameScenario={gameScenario}
								players={players}
								game={game}
							/>
						)}
						{game.hasFinal && screen === "final_choice" && (
							<WaitingFinalChoice
								finalPlayers={finalPlayers}
								countFinalPlayers={finalPlayers.length}
								playersPictures={playersPictures}
							/>
						)}
						{game.hasFinal && screen === "final" && (
							<FinalProgress
								finalPlayers={finalPlayers}
								finalPlayersOrder={finalPlayersOrder}
								countFinalPlayers={finalPlayers.length}
								playersPictures={playersPictures}
							/>
						)}
						{game.hasFinal &&
							screen === "post_game" &&
							stepStats === null && (
								<Winner
									winners={finalWinners}
									playersPictures={playersPictures}
								/>
							)}
						{screen === "finish" && <Finish />}
					</div>
				</>
			)}
			{displayFirework && <FireworksApp />}
		</main>
	);
}

export default App;
