import logoHilariousLabsImg from "../../assets/img/logo-hilarious-labs.png";

function Header({ game }) {
	return (
		<header>
			<div></div>
			<div className="game-container">
				<img src={logoHilariousLabsImg} />
				{game.state === "FINISHED" ? (
					<span>Restons connectés !</span>
				) : (
					<span>
						{/* Expérience  */}
						Meeting n°{game.ref}
					</span>
				)}
			</div>
			<div className="date-container"></div>
		</header>
	);
}

export default Header;
