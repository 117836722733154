function ModalFinalChoice({ player }) {
	return (
		<>
			<div className="bg-shadow"></div>
			<div className="box-go-tablet">
				<h1>Bravo {player.firstName}</h1>
				<div>
					Tu as remporté le classement général &#x1F680;
					<br />
					<br />
					Tu auras donc le choix du défi final !<br />
					<br />
					Dirige-toi vers la tablette et sélectionne celui de ton
					choix !
				</div>
			</div>
		</>
	);
}

export default ModalFinalChoice;
