function TeamDetails({ team, dataPlayers, index }) {
	const playersFirstname = team.players.map((playerId) => {
		return dataPlayers[playerId].firstName;
	});
	return (
		<div>
			Equipe {index + 1} : {playersFirstname.join(" - ")}
		</div>
	);
}

export default TeamDetails;
