import { useState, useEffect } from "react";
import Button from "../Button/Button";

function InputAppCode({ handleClickButtonSetAppCode }) {
	const [appCodeInput, setAppCodeInput] = useState("");
	const [isBtnActive, setIsBtnActive] = useState(false);

	useEffect(() => {
		if (appCodeInput.length === 8) {
			setIsBtnActive(true);
		} else {
			setIsBtnActive(false);
		}
	}, [appCodeInput]);

	return (
		<div className="loader-container" style={{ flexDirection: "row" }}>
			<span className="input-app-code-text">GAME-</span>
			<input
				className="input-app-code"
				type="text"
				value={appCodeInput}
				onChange={(e) => setAppCodeInput(e.target.value.toUpperCase())}
				maxLength={8}
			/>
			<Button
				isBtnActive={isBtnActive}
				handleClickButton={() =>
					handleClickButtonSetAppCode(appCodeInput)
				}
			/>
		</div>
	);
}

export default InputAppCode;
