import qrCodeInsta from "./../../assets/img/qr_code_insta.png";
import qrCodeLinkedin from "./../../assets/img/qr_code_in.png";
import qrCodeGoogle from "./../../assets/img/qr_code_google.png";
import fleche from "./../../assets/img/fleche_jaune.png";

function Finish() {
	return (
		<div className="finish">
			<p></p>
			<div className="follow-us-qrcode">
				<div style={{ flex: 2 }}>
					<div className="media">
						<img className="icon" src={qrCodeLinkedin} />
						<img className="icon" src={qrCodeInsta} />
						<img className="icon" src={qrCodeGoogle} />
					</div>
					<div className="flex-row-1">
						<p className="connect">
							<img src={fleche} />
							Restons
							<br />
							connectés !
						</p>
						<p className="google">
							<img src={fleche} />
							Tu t'es bien amusé.e ?<br />
							Aides-nous à propager
							<br />
							le pouvoir du rire en laissant
							<br />
							un avis sur Google !
						</p>
					</div>
				</div>
			</div>
			<div className="follow-us"></div>
		</div>
	);
}

export default Finish;
