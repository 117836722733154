import RankingList from "../RankingList/RankingList";
import StatsContentBadLoser from "./StatsContentBadLoser";
import StatsContentBadWinner from "./StatsContentBadWinner";
import StatsContentBestLoser from "./StatsContentBestLoser";
import StatsContentBestWinner from "./StatsContentBestWinner";
import StatsFull from "./StatsFull";

function StatsContent({
	stepStats,
	stats,
	players,
	playersPictures,
	dataScoring,
}) {
	let content;
	if (stepStats === "ranking") {
		content = (
			<RankingList
				players={players}
				playersPictures={playersPictures}
				dataScoring={dataScoring}
			/>
		);
	} else if (stepStats === "bestWinners") {
		content = (
			<StatsContentBestWinner
				stat={stats.bestWinners}
				playersPictures={playersPictures}
			/>
		);
	} else if (stepStats === "badWinners") {
		content = (
			<StatsContentBadWinner
				stat={stats.badWinners}
				playersPictures={playersPictures}
			/>
		);
	} else if (stepStats === "bestLosers") {
		content = (
			<StatsContentBestLoser
				stat={stats.bestLosers}
				playersPictures={playersPictures}
			/>
		);
	} else if (stepStats === "badLosers") {
		content = (
			<StatsContentBadLoser
				stat={stats.badLosers}
				playersPictures={playersPictures}
			/>
		);
	} else if (stepStats === "fullStats") {
		content = (
			<StatsFull
				fullStats={stats.fullStats}
				players={players}
				playersPictures={playersPictures}
			/>
		);
	}

	return <>{content}</>;
}

export default StatsContent;
