import okImg from "./../../assets/img/ok.png";

function Button({ isBtnActive, handleClickButton }) {
	return (
		<div
			className={"button" + (isBtnActive ? " buttonActive" : "")}
			style={{ backgroundImage: `url(${okImg})` }}
			onClick={handleClickButton}
		></div>
	);
}

export default Button;
