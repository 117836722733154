function StatsContentBadWinner({ stat, playersPictures }) {
	const picture = playersPictures[stat.playerId];
	return (
		<div className="stats-content-box">
			<div className="picture-profil">
				<img src={picture} />
			</div>
			<p className="text">
				Tu as fais rire <span>{stat.count}</span> fois...
			</p>
		</div>
	);
}

export default StatsContentBadWinner;
