import defaultImg from "../../assets/img/professor.png";
import TimerScenario from "../TimerScenario/TimerScenario";
import TeamDetails from "../TeamDetails/TeamDetails";

function BoxScenario({ gameScenario, players, game }) {
	const gameScenarioData = gameScenario.gameScenario;
	const hasDefinedScenario = gameScenarioData.hasOwnProperty("scenario");

	const description = gameScenarioData.message;
	let dataPlayers;
	if (hasDefinedScenario) {
		if (gameScenario.mode === "TEAM" && gameScenario.teams.length > 0) {
			dataPlayers = players.reduce((acc, onePlayer) => {
				acc[onePlayer.id] = onePlayer;
				return acc;
			}, {});
		}
	}

	let countdown = <></>;
	let hasCountdown = false;
	if (
		gameScenarioData.hasOwnProperty("startDate") &&
		gameScenarioData.hasOwnProperty("duration") &&
		gameScenarioData.duration > 0
	) {
		hasCountdown = true;
		const date = new Date(gameScenarioData.startDate);
		countdown = (
			<div className="content-left-bloc-bottom">
				<TimerScenario
					date={
						date.getTime() + gameScenarioData.duration * 60 * 1000
					}
				/>
			</div>
		);
	}

	return (
		<div className="left-bloc">
			<div className="content-left-bloc">
				<div className="content-left-bloc-top">
					<img src={game && game.theme && game.theme !== null ? game.theme.characterImage : defaultImg} />
					<div>
						<span
							className="message-content"
							dangerouslySetInnerHTML={{
								__html: description,
							}}
						></span>
						{gameScenario.mode === "TEAM" &&
						gameScenario.teams.length > 0 ? (
							<span className="message-content message-content-small">
								{gameScenario.teams.map((team, index) => {
									return (
										<TeamDetails
											key={index}
											team={team}
											dataPlayers={dataPlayers}
											index={index}
										/>
									);
								})}
							</span>
						) : (
							<></>
						)}
					</div>
				</div>
				{hasCountdown && countdown}
			</div>
		</div>
	);
}

export default BoxScenario;
