function FinalProgressPlayer({
	player,
	index,
	order,
	countFinalPlayers,
	picture,
}) {
	let indice = Math.floor(100 / (countFinalPlayers - 1));
	let left = (order - 1) * indice;
	if (left === 0) {
		left = null;
	} else {
		left = left.toString() + "%";
	}
	return (
		<div className="final-player">
			<div
				className={
					"final-player-bg" +
					(player.isFinalEliminate ? " eliminate" : "")
				}
			>
				<div
					className="player-picture"
					style={{
						left: left === null ? "0" : left,
						transform:
							left === null
								? "translate(0, -50%)"
								: "translate(-" + left + ", -50%)",
						backgroundImage: `url("${picture}")`,
					}}
				>
					<div className="final-player-box-name">&nbsp;</div>
					<div className="final-player-name">{player.firstName}</div>
					{player.isFinalEliminate && (
						<div className="eliminate-bg"></div>
					)}
				</div>
			</div>
		</div>
	);
}

export default FinalProgressPlayer;
