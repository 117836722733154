import loader from "./../../assets/img/loader.gif";

function Loading({ state }) {
	let text = "Chargement de la partie en cours...";
	if (state === "WAITING_PLAYERS") {
		text = "En attente des autres joueurs...";
	} else if (state === "POST_GAME") {
		text = "En attente des stats...";
	} else if (state === "FINISHED") {
		text = "La partie est terminée !";
	}
	return (
		<div className="loader-container">
			<img src={loader} />
			<div className="text">{text}</div>
		</div>
	);
}

export default Loading;
