import firstImg from "../../assets/img/first.png";
import secondImg from "../../assets/img/second.png";
import thirdImg from "../../assets/img/third.png";

function Player({ player, picture, dataScoring }) {
	let classNameRanking = "";
	let numberRanking = <span>{player.ranking}</span>;
	let hasPrevWinner = false;
	let hasPrevLoser = false;
	let pointsPrev = null;

	if (
		dataScoring.hasOwnProperty("winners") &&
		dataScoring.winners.hasOwnProperty(player.id)
	) {
		hasPrevWinner = true;
		pointsPrev = "+" + String(dataScoring.winners[player.id]);
	} else if (
		dataScoring.hasOwnProperty("losers") &&
		dataScoring.losers.hasOwnProperty(player.id)
	) {
		hasPrevLoser = true;
		pointsPrev = dataScoring.losers[player.id];
	}

	if (player.ranking === 1) {
		numberRanking = <img src={firstImg} />;
		classNameRanking = "first";
	} else if (player.ranking === 2) {
		numberRanking = <img src={secondImg} />;
		classNameRanking = "second";
	} else if (player.ranking === 3) {
		numberRanking = <img src={thirdImg} />;
		classNameRanking = "third";
	}

	if (hasPrevLoser) {
		classNameRanking += " prev-loser";
	} else if (hasPrevWinner) {
		classNameRanking += " prev-winner";
	}

	return (
		<div className="ranking">
			<div className={"line " + classNameRanking}>
				<span className="number">{numberRanking}</span>
				<div
					className="picture"
					style={{ backgroundImage: `url("${picture}")` }}
				></div>
				<span className="name">
					{player.firstName}
					{/* {rankMoved && (
						<FontAwesomeIcon
							icon={faArrowUp}
							className="icon-ranking-move up"
						/>
					)} */}
				</span>
				{/* <span className="attack">
					<img src={attackImg.src} />
					<span>+130</span>
				</span>
				<span className="defense">
					<img src={defenseImg.src} />
					<span>-30</span>
				</span> */}
				<span className="total">
					{(hasPrevWinner || hasPrevLoser) && (
						<span className="info-prev-scoring">{pointsPrev}</span>
					)}
					<span className="point">
						<span>{player.totalPoint}</span>
					</span>
				</span>
			</div>
		</div>
	);
}

export default Player;
