import Countdown from "react-countdown";

function TimerScenario({ date }) {
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			return (
				<div id="timer2">
					<div>
						<span className="minutes">00</span>
					</div>
					<div className="separator">:</div>
					<div>
						<span className="seconds">00</span>
					</div>
				</div>
			);
		} else {
			return (
				<div id="timer2">
					<div>
						<span className="minutes">
							{String(minutes).padStart(2, "0")}
						</span>
					</div>
					<div className="separator">:</div>
					<div>
						<span className="seconds">
							{String(seconds).padStart(2, "0")}
						</span>
					</div>
				</div>
			);
		}
	};

	return <Countdown date={date} renderer={renderer} autoStart={true} />;
}

export default TimerScenario;
