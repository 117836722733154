function StatsContentBadLoser({ stat, playersPictures }) {
	const picture = playersPictures[stat.playerId];
	return (
		<div className="stats-content-box">
			<div className="picture-profil">
				<img src={picture} />
			</div>
			<p className="text">
				Tu as rigolé seulement <span>{stat.count}</span> fois !
			</p>
		</div>
	);
}

export default StatsContentBadLoser;
