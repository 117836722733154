import Player from "../Player/Player";

function RankingList({ players, playersPictures, dataScoring }) {
	const rankingList = players.map((player) => (
		<Player
			key={player.id}
			player={player}
			picture={playersPictures[player.id]}
			dataScoring={dataScoring}
		/>
	));
	return <div id="ranking-list">{rankingList}</div>;
}

export default RankingList;
