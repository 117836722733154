import professorImg from "../../assets/img/professor.png";
import FinalPlayerPicture from "./FinalPlayerPicture";

function WaitingFinalChoice({
	finalPlayers,
	countFinalPlayers,
	playersPictures,
}) {
	return (
		<div className="final-right-bloc">
			<div className="content-final-right-bloc">
				<div className="content-final-right-bloc-top">
					<img src={professorImg} />
					<div>
						<div className="title">
							Du BRUIT pour nos grands FINALISTES !!
						</div>
						<div
							className={
								"players-picture players-picture-" +
								countFinalPlayers
							}
						>
							{finalPlayers.map((finalPlayer) => (
								<FinalPlayerPicture
									picture={playersPictures[finalPlayer.id]}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WaitingFinalChoice;
